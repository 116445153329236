import React from "react";

const TraslateHome = ({ cambiarId }) => {
    const id1 = "/paginaspanish/647a59c7740ffc1fbf3237f8"; //Variable en español
    const id2 = "/paginas/646d36c5aa6fca7c8392f9e0"; //Variable en Ingles

    return (
        <div
            className="w-full h-screen relative flex items-center bg1"
        >
            <div className="relative flex flex-col items-center w-full">
                <img
                    src={'https://firebasestorage.googleapis.com/v0/b/clientesimages.appspot.com/o/Paginas%2F646d36c5aa6fca7c8392f9e0%2FiconAndLogo%2FHail-Storm-Exteriors%2C-Inc.gif?alt=media&token=d7fc6fee-f43b-4fa1-bacc-fc9643ef3d08'}
                    alt="no-logo"
                    className="w-[80%] md:w-[30%] pb-5"
                />
                <h1 className="text-white capitalize pb-5">Sitio / Site</h1>
                <div className="flex">
                    <div className="mr-3">
                        <button
                            onClick={() => cambiarId({id: id1, l: 'es'})}
                            className="px-5 py-3 rounded-2xl bg-red-900"
                        >
                            Español
                        </button>
                    </div>
                    <div className="ml-3">
                        <button
                            onClick={() => cambiarId({id: id2, l: 'en'})}
                            className="px-5 py-3 rounded-2xl bg-red-600"
                        >
                            English
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TraslateHome