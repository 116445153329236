import React from 'react'

const ValuesContent = ({ mission, vision, whychoose, img1, img2, img3, rpdata }) => {
  return (
    <section className='w-4/5 mx-auto grid grid-cols-1 md:grid-cols-3 gap-20 md:gap-10 py-20'>

      <div
        data-aos="fade-up"
        data-aos-duration="2000"
      >
        <picture>
          <img
            src={img1}
            alt='images mission'
            className='w-full h-[250px] object-cover rounded-sm pb-5'
          />
        </picture>
        <div className='text-center'>
          <h3>
            {
            !rpdata?.spanishVersion?.isActive ? 'Mision' : 'Mission'
          }
          </h3>
          <p>{mission}</p>
        </div>
      </div>

      <div
        className='rounded-sm min-h-[350px] md:h-full bg-cover bg-center bg-no-repeat relative before:absolute before:w-full before:h-full before:bg-[#ffffff82]'
        style={{
          backgroundImage: `url("${img2}")`
        }}
        data-aos="fade-down"
        data-aos-duration="2000"
      >
        <div className='relative bgOverlayColor w-[90%] mx-auto h-full flex items-center -mt-5 rounded-sm py-5'>
          <div className='text-center text-white px-5'>
            <h3 className='capitalize'>vision</h3>
            <p>{vision}</p>
          </div>
        </div>
      </div>

      <div
        data-aos="fade-up"
        data-aos-duration="2000"
      >
        <picture>
          <img
            src={img3}
            alt='images why choose us'
            className='w-full h-[250px] object-cover rounded-sm pb-5'
          />
        </picture>
        <div className='text-center'>
          <h3 className='capitalize'>
          {
            !rpdata?.spanishVersion?.isActive ? 'Por Que Elegirnos' : 'Why Choose Us'
          }
          </h3>
          <p>{whychoose}</p>
        </div>
      </div>

    </section>
  )
}

export default ValuesContent