import axios from "axios";
import React, { useEffect, useState } from 'react'
import Loader from "../components/global/Loader";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./Home";
import About from "./About";
import Services from "./Services";
import Gallery from "./Gallery";
import Contact from "./Contact";
import { GlobalDataContext } from "../context/context";
import ServicesDetail from "../components/Services/ServicesDetail";
import GalleryDetail from "../components/Gallery/GalleryDetail";
import Reviews from "./Reviews";
import OurVideos from "./OurVideos";
import FindUsOn from "../components/global/FindUsOn";
import ErrorPages from "./ErrorPages";

const Generic = ({ id }) => {
    const [rpdata, setrpdata] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    //useEffect para peticion al servidor
    useEffect(() => {
        async function fetchData() {
            setIsLoading(true);
            try {
                const response = await axios({
                    baseURL: "https://servidorpublico.herokuapp.com/api",
                    url: `${id}`,
                    method: "get",
                });
                //se convierte la info en objetos
                setrpdata(JSON.parse(JSON.stringify(response.data)));

                // paletas de colores en la web
                document.documentElement.style.setProperty('--main-color', response.data.styles.mainColor);
                document.documentElement.style.setProperty('--secondary-color', response.data.styles.secondaryColor);
                document.documentElement.style.setProperty('--three-color', response.data.styles.thirdColor);
                document.documentElement.style.setProperty('--heading-color', response.data.styles.headingTextColor);
                document.documentElement.style.setProperty('--paragraph-color', response.data.styles.paragraphTextColor);
                document.documentElement.style.setProperty('--bg-footer', response.data.styles.bgFooter);
                document.documentElement.style.setProperty('--btn', response.data.styles.btn);
                document.documentElement.style.setProperty('--btn-hover', response.data.styles.btnHover);
                document.documentElement.style.setProperty('--scroll-color', response.data.styles.scrollColor);
                document.documentElement.style.setProperty('--icons-menu-movil', response.data.styles.iconsMenuMovil);
                document.documentElement.style.setProperty('--overlay-video-content', response.data.styles.overlayColor);

                // loadings 
                setIsLoading(false)

            } catch (error) {
                console.log(error);
            }
        }
        fetchData();
    }, [id]);

    return isLoading ? (
        <Loader />
    ) : (
        <div className="App">
            <GlobalDataContext.Provider value={{ rpdata }}>
                {
                    !rpdata?.spanishVersion?.isActive ?
                        <Router>
                            <Routes>
                                <Route exact path="/" element={<Home />} />
                                <Route path="/sobre-nosotros" element={<About />} />
                                <Route path="/servicios" element={<Services />}></Route>
                                <Route path="/:id" element={<ServicesDetail />} />
                                <Route path="/galeria" element={<Gallery />} />
                                <Route path="/galeria/:id" element={<GalleryDetail />} />
                                <Route path="/comentarios" element={<Reviews />} />
                                <Route path="/contactenos" element={<Contact />} />
                                <Route path="/find-us-on" element={<FindUsOn />} />
                                <Route path="*" element={<ErrorPages />} />
                                <Route path="/nuestros-videos" element={<OurVideos />} />
                            </Routes>
                        </Router>
                        :
                        <Router>
                            <Routes>
                                <Route exact path="/" element={<Home />} />
                                <Route path="/about" element={<About />} />
                                <Route path="/services" element={<Services />}></Route>
                                <Route path="/:id" element={<ServicesDetail />} />
                                <Route path="/gallery" element={<Gallery />} />
                                <Route path="/gallery/:id" element={<GalleryDetail />} />
                                <Route path="/reviews" element={<Reviews />} />
                                <Route path="/contact" element={<Contact />} />
                                <Route path="/our-videos" element={<OurVideos />} />
                                <Route path="/find-us-on" element={<FindUsOn />} />
                                <Route path="*" element={<ErrorPages />} />
                                <Route path="/nuestros-videos" element={<OurVideos />} />
                            </Routes>
                        </Router>
                }
            </GlobalDataContext.Provider>
        </div>
    );
}

export default Generic