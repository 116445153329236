import React, { useState, useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import Form from "../Contact/Form";

const Modal = () => {
  const [showModal, setShowModal] = useState(true);
  const { rpdata } = useContext(GlobalDataContext);

  const logo1 = 'https://firebasestorage.googleapis.com/v0/b/archivos-multimedia.appspot.com/o/Hail-Storm-Exteriors%2C-Inc_1.gif?alt=media&token=244a4848-1dff-44a2-8283-3ae10c400200'

  return (
    <>
      {showModal ? (
        <>
          <div
            className="relative z-50"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
          >
            <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity"></div>

            <div className="fixed inset-0 z-10 overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white pt-5">
                    <div className="sm:flex sm:items-start">
                      <button
                        className="absolute top-3 right-3 px-2 rounded-full text-red-500 hover:text-[#00B3B3]"
                        id="modal-title"
                        onClick={() => setShowModal(false)}
                      >
                        <i className="fa fa-times"></i>
                      </button>
                      <div className="w-full flex justify-center item-center flex-col ">
                        <div className="w-full flex justify-center ">
                          <img
                            src={logo1}
                            className="md:w-[50%] w-[70%]"
                            alt="Not Found"
                            loading="lazy"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                    <h4 className="text-center">
                    {
            !rpdata?.spanishVersion?.isActive ? 'Solicitar Un Estimado Gratuito' : 'Request An Estimate Now'
          }
                    </h4>
                    <Form />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default Modal;
